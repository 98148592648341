import React from 'react'

const Dashboard = () => {
    return (
        <React.Fragment>
            <p>dashboard</p>
        </React.Fragment>
    )
}

export default Dashboard